<template>
    <div class="bindRealNameAccount_page">
        <van-loading type="spinner"
                     v-if="loading"
                     color="#F0D19E"
                     vertical>加载中...</van-loading>
        <div v-if="!loading">
            <van-form validate-first
                      class="boundForm">
                <van-field v-model="tel"
                           label="手机号"
                           name="tel"
                           type="tel"
                           placeholder="本人手机号"
                           :rules="[
          { trigger: 'onBlur', message: '请输入手机号' },
          { trigger: 'onBlur', validator: asyncValidatorIsPhone, message: '手机号格式错误' },
        ]"
                           :disabled="disabled"
                           maxlength="11" />
                <van-field v-model="name"
                           name="name"
                           label="姓名"
                           placeholder="本人姓名"
                           :rules="[{ required: true }]"
                           :disabled="disabled"
                           maxlength="10" />
                <van-field v-model="idCard"
                           label="身份证"
                           name="idCard"
                           placeholder="本人身份证"
                           :rules="[
          { trigger: 'onBlur', message: '请输入身份证号' },
          { trigger: 'onBlur', validator: asyncValidatorID, message: '身份证格式错误' }
        ]"
                           :disabled="disabled"
                           maxlength="18" />
                <van-button round
                            block
                            type="info"
                            native-type="submit"
                            :disabled="isDisabled"
                            :class="{'btnTip': isDisabled}"
                            class="btn"
                            loading-text="保存中..."
                            :loading="btnLoading"
                            @click="handleBindNext">{{subBtnTip}}</van-button>
            </van-form>
            <div class="tip">
                <div>提现实名认证须知</div>
                <div>
                    1.根据国家个人所得税法有关规定，用户提现转账需进行实名认证，验证通过后才可进行提现转账操作，请依法进行相应实名认证流程，带来不便，请谅解。<br />2.验证实名账户前准备<br />&nbsp;&nbsp;a.提现人手机号， 姓名，
                    身份证，提现账户（银行卡账号）<br />&nbsp;&nbsp;b.注意：以上三要素需为同一人才可完成正常提现，请认证前注意核实以上信息
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import './index.less'
import { Popup, Picker, Dialog } from 'vant'
import { getToken, urlParse } from '@/utils/tokenUtils'
import { isID, isPhone } from '@/utils/regExp'
import {
    ApplyUserRealName,
    GetUserRealNameWithEncrypt,
    GetUserRealName
} from '@/service/withdraw'

function convertLastCharToUppercase(s) {
  if (s && s[s.length - 1].match(/[a-z]/)) {
    return s.slice(0, -1) + s[s.length - 1].toUpperCase()
  }
  return s
}
export default {
    components: {
        vanPopup: Popup,
        vanPicker: Picker,
        vanDialog: Dialog.Component
    },
    data() {
        return {
            loading: false,
            subBtnTip: '下一步', // 按钮的默认文字
            name: '', // 本人姓名
            idCard: '', // 本人身份证
            tel: '', // 本人手机号
            disabled: false,
            btnLoading: false,
            modalText: '是否确认绑定',
            certifyStatus: 0,
            certifyLink: '',
            urlData: {},
            backConfirm: true
        }
    },
    // beforeRouteLeave(to, from, next) {
    //   // 即将跳转的路由地址
    //   if (this.backConfirm) {
    //     Dialog.confirm({
    //       title: '是否放弃绑定账号',
    //       cancelButtonText: '否',
    //       confirmButtonText: '是',
    //       confirmButtonColor: '#333',
    //       className: 'overConfirm',
    //       getContainer: () => {
    //         return document.querySelector('.bindRealNameAccount_page')
    //       }
    //     })
    //       .then(() => {
    //         next(true)
    //       })
    //       .catch(() => {
    //         document.querySelector('.overConfirm').remove()
    //         next(false)
    //       })
    //   } else {
    //     next(true)
    //   }
    // },
    computed: {
        // 下一步 按钮是否禁用
        isDisabled() {
            return !(this.name && this.idCard && this.tel)
        }
    },
    watch: {},
    created() {
        const urlData = urlParse(window.location.href)
        console.log('urlData', urlData)
        if (urlData.modify == 'false') {
            this.subBtnTip = '下一步'
        }
        if (urlData.modify == 'true') {
            this.loading = true
            this.subBtnTip = '查看'
            this.disabled = true
            GetUserRealNameWithEncrypt().then((res) => {
                const { code, data = {}, message } = res
                if (code == '0') {
                    this.tel = (data && data.phone) || ''
                    this.name = (data && data.realName) || ''
                    this.idCard = (data && data.identityCode) || ''
                } else {
                    this.$toast(message)
                }
                this.loading = false
            })
        }
    },
    mounted() {},
    beforeDestroy() {
        clearInterval(this.timer)
    },
    methods: {
        // 身份证校验
        asyncValidatorID(val) {
            if (val) {
                return this.disabled ? true : isID(val)
            }
        },
        // 手机号校验
        asyncValidatorIsPhone(val) {
            if (val) {
                return this.disabled ? true : isPhone(val)
            }
        },
        // 点击 下一步或重新绑定 按钮
        handleBindNext() {
            if (this.subBtnTip == '查看') {
                GetUserRealName().then((res) => {
                    const { code, data = {}, message } = res
                    if (code == '0') {
                        this.tel = (data && data.phone) || ''
                        this.name = (data && data.realName) || ''
                        this.idCard = (data && data.identityCode) || ''
                        this.subBtnTip = '修改'
                        this.disabled = false
                    } else {
                        this.$toast(message)
                    }
                })
                return
            }
            if (this.subBtnTip == '下一步' || this.subBtnTip == '修改') {
                this.btnLoading = true
                ApplyUserRealName({
                    idName: this.name,
                    idCard: convertLastCharToUppercase(this.idCard),
                    mobile: this.tel
                }).then((res) => {
                    const { code, data } = res
                    if (code == '0') {
                        this.backConfirm = false
                        this.$router.go(-1)
                    } else {
                        this.btnLoading = false
                        this.backConfirm = true
                        this.$toast(res.message)
                        if (document.querySelector('.overConfirm')) {
                            document.querySelector('.overConfirm').remove()
                        }
                    }
                })
            }
        }
    }
}
</script>

<style lang="less" scoped>
</style>
