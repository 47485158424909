var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bindRealNameAccount_page"},[(_vm.loading)?_c('van-loading',{attrs:{"type":"spinner","color":"#F0D19E","vertical":""}},[_vm._v("加载中...")]):_vm._e(),(!_vm.loading)?_c('div',[_c('van-form',{staticClass:"boundForm",attrs:{"validate-first":""}},[_c('van-field',{attrs:{"label":"手机号","name":"tel","type":"tel","placeholder":"本人手机号","rules":[
      { trigger: 'onBlur', message: '请输入手机号' },
      { trigger: 'onBlur', validator: _vm.asyncValidatorIsPhone, message: '手机号格式错误' },
    ],"disabled":_vm.disabled,"maxlength":"11"},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}}),_c('van-field',{attrs:{"name":"name","label":"姓名","placeholder":"本人姓名","rules":[{ required: true }],"disabled":_vm.disabled,"maxlength":"10"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('van-field',{attrs:{"label":"身份证","name":"idCard","placeholder":"本人身份证","rules":[
      { trigger: 'onBlur', message: '请输入身份证号' },
      { trigger: 'onBlur', validator: _vm.asyncValidatorID, message: '身份证格式错误' }
    ],"disabled":_vm.disabled,"maxlength":"18"},model:{value:(_vm.idCard),callback:function ($$v) {_vm.idCard=$$v},expression:"idCard"}}),_c('van-button',{staticClass:"btn",class:{'btnTip': _vm.isDisabled},attrs:{"round":"","block":"","type":"info","native-type":"submit","disabled":_vm.isDisabled,"loading-text":"保存中...","loading":_vm.btnLoading},on:{"click":_vm.handleBindNext}},[_vm._v(_vm._s(_vm.subBtnTip))])],1),_vm._m(0)],1):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip"},[_c('div',[_vm._v("提现实名认证须知")]),_c('div',[_vm._v(" 1.根据国家个人所得税法有关规定，用户提现转账需进行实名认证，验证通过后才可进行提现转账操作，请依法进行相应实名认证流程，带来不便，请谅解。"),_c('br'),_vm._v("2.验证实名账户前准备"),_c('br'),_vm._v("  a.提现人手机号， 姓名， 身份证，提现账户（银行卡账号）"),_c('br'),_vm._v("  b.注意：以上三要素需为同一人才可完成正常提现，请认证前注意核实以上信息 ")])])
}]

export { render, staticRenderFns }