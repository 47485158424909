export const isPhone = (aPhone) => {
  let bValidate = RegExp(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/).test(aPhone);
  if (bValidate) {
    return true;
  } else {
    return false;
  }
}


export const isID = (id) => {
  let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  let _IDre15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/;
  if (_IDRe18.test(id) || _IDre15.test(id)) {
    return true;
  } else {
    return false;
  }
}

export const isSpace = (value) => {
  return value.replace(/\s+/g, "");
}